<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="商品名稱" prop="name" >
        <a-input v-model="form.name" placeholder="请输入商品名稱" />
      </a-form-model-item>
      <a-form-model-item label="原价" prop="originalPrice" >
        <a-input v-model="form.originalPrice" placeholder="请输入原价" />
      </a-form-model-item>
      <a-form-model-item label="现价" prop="currentPrice" >
        <a-input v-model="form.currentPrice" placeholder="请输入现价" />
      </a-form-model-item>
      <a-form-model-item label="封面" prop="cover" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest'
          :beforeUpload="beforeUpload"
          @change="handleChange"
        >
          <img v-if="form.cover" :src="form.cover" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>
      <a-form-model-item label="轮播图" prop="viewImage" >
        <a-upload
          class="avatar-uploader"
          name="file"
          listType="picture-card"
          accept=".jpg,.png"
          :multiple="isMultiple"
          :beforeUpload="beforeUpload2"
          :fileList="fileList"
          :disabled="false"
          :isMultiple="isMultiple"
          :customRequest='fileUploadRequest2'
          :remove="handleFileRemove"
          @preview="handlePreview">
            <div v-if="fileList.size > 0">
              <img :src="getAvatarView()" style="height:104px;max-width:300px"/>
            </div>
            <div v-else >
                <a-icon :type="uploadLoading ? 'loading' : 'plus'"/>
                <div class="ant-upload-text">{{ text }}</div>
            </div>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage"/>
            </a-modal>
        </a-upload>
        <span style="color: darkorange">只支持.jpg格式，最多8张</span>
      </a-form-model-item>

      <a-form-model-item label="轮播視頻" prop="viewVideo" >
        <a-upload
          listType="picture-card"
          class="avatar-uploader"
          :showUploadList="false"
          :customRequest='fileUploadRequest3'
          :beforeUpload="beforeUpload3"
          @change="handleChange3" accept=".mp4"
        >
          <img v-if="form.viewVideo" :src="form.viewVideo + '?x-oss-process=video/snapshot,t_30000,f_jpg,w_0,h_0,m_fast,ar_auto'" alt="图片" style="height:104px;max-width:300px"/>
          <div v-else>
            <a-icon :type="uploadLoading3 ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
        <span style="color:#ff0000">图片限制小于5M </span>
      </a-form-model-item>
      <a-form-model-item label="商品详情" prop="detail" >
        <a-input v-model="form.detail" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="淘宝链接" prop="taobaoLink" >
        <a-input v-model="form.taobaoLink" placeholder="请输入淘宝链接" />
      </a-form-model-item>
      <a-form-model-item label="所属品牌" prop="brandId" >
        <a-select placeholder="请选择所属品牌" optionFilterProp="children" show-search v-model="form.brandId">
          <a-select-option v-for="(item,key) in brandList" :key="item.name" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="所属分类" prop="typeId" >
        <a-select placeholder="请选择所属分类" optionFilterProp="children" show-search v-model="form.typeId">
          <a-select-option v-for="(item,key) in typeList" :key="item.name" :value="item.id">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGoods, addGoods, updateGoods } from '@/api/goods/goods'
import request from "@/utils/request"
import UploadFileToOSS from "@/utils/upload-file-to-oss"
import JImageUpload from '@/components/jeecg/JImageUpload'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    JImageUpload
  },
  data () {
    return {
      isMultiple: false,
      submitLoading: false,
      previewVisible: false,
      uploadLoading:false,
      uploadLoading2:false,
      uploadLoading3:false,
      formTitle: '',
      previewImage: null,
      fileList: [],
      brandList: [],
      typeList: [],
      // 表单参数
      form: {
        id: null,

        name: null,

        storeId: null,

        originalPrice: null,

        currentPrice: null,

        cover: null,

        viewImage: null,

        viewVideo: null,

        detail: null,

        taobaoLink: null,

        brandId: null,

        typeId: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '商品名稱不能为空', trigger: 'blur' }
        ],
        originalPrice: [
          { required: true, message: '原价不能为空', trigger: 'blur' }
        ],
        currentPrice: [
          { required: true, message: '现价不能为空', trigger: 'blur' }
        ],
        cover: [
          { required: true, message: '封面不能为空', trigger: 'blur' }
        ],
        viewImage: [
          { required: true, message: '轮播图不能为空', trigger: 'blur' }
        ],
        typeId: [
          { required: true, message: '所属分类不能为空', trigger: 'blur' }
        ],
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.brandInit();
    this.typeInit();
  },
  methods: {
    showDetail(video){
      this.previewImage = video;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false
    },
    // 文件刪除
    handleFileRemove (file) {
      let url = file.url;
      let fileList = this.fileList;
      let newFileList = [];
      for(var a=0;a<fileList.length;a++){
        if(url != fileList[a].url){
          newFileList.push(fileList[a]);
        }
      }
      this.fileList = newFileList;
    },
    getvideo(data) {
      let _this = this;
      _this.modalVideo = false;
      this.video = data;
      let videoHTML =
        '<video src="' + this.video + '" controls style="max-width:100%;min-height:500rpx"></video><p><br></p>';
      this.editor.cmd.do('insertHTML', videoHTML);
    },
    // 预览
    handlePreview (file) {
      this.previewImage = file.url || file.thumbUrl
      this.previewVisible = true
    },
    getAvatarView(){
      if(this.fileList.length>0){
        let url = this.fileList[this.fileList.length-1].url
        return url;
      }
    },
    fileUploadRequest(fileInfo) {
      this.uploadLoading = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    fileUploadRequest3(fileInfo) {
      this.uploadLoading3 = true

      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          fileInfo.onSuccess();
        },
      });
    },
    beforeUpload2: function(file){
      var fileType = file.type;
      if(fileType.indexOf('image')<0){
        this.$message.warning('请上传图片');
        return false;
      }
    },
    beforeUpload3: function(file){

    },
    beforeUpload: function (file) {
      return new Promise((resolve, reject) => {
        if(file.size>5*1024*1000){
          this.$message.warning("图片大小不能超过5M----")
          return reject(false)
        }

        var fileType = file.type;
        if (fileType.indexOf('image') < 0) {
          this.$message.warning('请上传图片');
          return reject(false);
        }
        resolve(true)
      });
    },

    fileUploadRequest2(fileInfo) {
      let that = this
      if(that.fileList.length >= 8){
        this.$message.warn('图片最多不能超过八张!')
        return;
      }
      new UploadFileToOSS([fileInfo.file], {
        finally: (res) => {
          // Log.info('multi-img-upload', res);
          const ossUrl = res[0].url;
          fileInfo.file.ossUrl = ossUrl;
          fileInfo.file.ossName = ossUrl;
          that.fileList.push({
            uid: '-'+parseInt(Math.random()*10000+1,10),
            name: this.getFileName(ossUrl),
            status: 'done',
            url: ossUrl,
            response:{
              status:"history",
              message:ossUrl
            }
          })
          fileInfo.onSuccess();
        },
      });
    },

    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading = true
        return
      }
      if (info.file.status === 'done') {
        // let response = info.file.response;
        this.uploadLoading = false;
        this.form.cover = info.file.originFileObj.ossUrl;
      }
    },
    handleChange3(info) {
      if (info.file.status === 'uploading') {
        this.uploadLoading3 = true
        return
      }
      if (info.file.status === 'done') {
        this.uploadLoading3 = false;
        this.form.viewVideo = info.file.originFileObj.ossUrl;
      }
    },
    brandInit(){
      request({
        url: '/platform/goods-brand/list',
        method: 'get'
      }).then(response => {
        this.brandList = response.data;
      })
    },
    typeInit(){
      request({
        url: '/platform/goods-type/list?level=3',
        method: 'get'
      }).then(response => {
        this.typeList = response.data;
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,

        name: null,

        storeId: null,

        originalPrice: null,

        currentPrice: null,

        cover: null,

        viewImage: null,

        viewVideo: null,

        detail: null,

        taobaoLink: null,

        brandId: null,

        typeId: null,
      }
/*      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })*/
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
      this.fileList = []
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGoods({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
      if(row.viewImage != null){
        this.initFileList(row.viewImage);
      }
    },
    getFileName(path){
      if(path.lastIndexOf("\\")>=0){
        let reg=new RegExp("\\\\","g");
        path = path.replace(reg,"/");
      }
      return path.substring(path.lastIndexOf("/")+1);
    },
    initFileList(paths){
      if(!paths || paths.length==0){
        this.fileList = [];
        return;
      }
      this.picUrl = true;
      let fileList = [];
      let arr = paths.split(",")
      for(var a=0;a<arr.length;a++){
        let url = this.imgerver+arr[a]
        fileList.push({
          uid: '-'+parseInt(Math.random()*10000+1,10),
          name: this.getFileName(arr[a]),
          status: 'done',
          url: arr[a],
          response:{
            status:"history",
            message:arr[a]
          }
        })
      }
      this.fileList = fileList
    },
    /** 提交按钮 */
    submitForm: function () {

      let fileList = this.fileList;
      let viewImage = [];
      for(var a=0;a<fileList.length;a++){
        viewImage.push(fileList[a].url)
      }
      this.form.viewImage = viewImage.join();

      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGoods(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addGoods(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less">

.imgbox {
  overflow: hidden;
  background-color: #f7f7f7;
  position: relative;
  cursor: pointer;

  width: 100%;
  height: 100%;
  margin: 0 4px 4px 0;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.videomask {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  line-height: 55px;
  background: rgba(0, 0, 0, .3);
  text-align: center;

  .icon {
    display: inline-block;
    font-size: 18px !important;
    color: #fff !important;
  }
}
</style>